"use client"
import ChatBotComponent from '@/components/modal/chatBotComponent'
import './globals.css'
import { useEffect } from 'react'

export default function RootLayout({ children, params }: { children: React.ReactNode, params: { lang: string } }) {

    useEffect(() => {
        const meta = document.createElement('meta')
        meta.name = 'viewport'
        meta.content = 'initial-scale=1, width=device-width'
        document.head.appendChild(meta)

        const favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = '/icon.png'
        favicon.type = 'image/png'
        favicon.setAttribute('sizes', '512x512')
        document.head.appendChild(favicon)

        const appleTouchIcon = document.createElement('link')
        appleTouchIcon.rel = 'apple-touch-icon'
        appleTouchIcon.href = '/apple-icon.png'
        appleTouchIcon.type = 'image/png'
        appleTouchIcon.setAttribute('sizes', '180x180');
        document.head.appendChild(appleTouchIcon)

        const title = document.createElement('title')
        title.innerText = 'Emkay - Company Profile'
        document.head.appendChild(title)

        return () => {
            document.head.removeChild(meta)
            document.head.removeChild(favicon)
            document.head.removeChild(appleTouchIcon)
            document.head.removeChild(title)
        }
    }, [])

    return (
        <html lang={params.lang}>
            <body>
                {children}
                <ChatBotComponent />
            </body>
        </html>
    )
}
